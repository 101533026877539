body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f6f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

html {
  font-size: 10px;
}

@media only screen and (min-aspect-ratio: 1/1) and (min-height: 800px) {
  html {
    font-size: 0.7vh;
  }
  /*body{*/
  /*  overflow: hidden;*/
  /*}*/
}

@media only screen and (min-height: 2000px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 9px;
  }

  /*body{*/
  /*  overflow: auto;*/
  /*}*/
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 380px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (max-width: 900px) {
  .desktopOnly {
    display: none;
  }
}

@media only screen and (min-width: 901px) {
  .mobileOnly {
    display: none;
  }
}
